import React, { useState, useEffect } from 'react';
import { Table, Container } from 'react-bootstrap';
import '../../styles/Events/Events.css';
import { events } from '../Events/eventsData'; // Importer les données des événements

const Events = () => {
  const [view, setView] = useState('upcoming');
  const [nextEvent, setNextEvent] = useState(null);

  const now = new Date();

  const createLocalDate = (dateString, timeString) => {
    const [year, month, day] = dateString.split('-');
    const [hour, minute] = timeString ? timeString.split(':') : [0, 0];
    return new Date(year, month - 1, day, hour, minute);
  };

  const upcomingEvents = events.filter(event => createLocalDate(event.date, event.time) > now);
  const pastEvents = events.filter(event => createLocalDate(event.date, event.time) <= now);

  useEffect(() => {
    const futureEvents = upcomingEvents
      .map(event => ({ ...event, date: createLocalDate(event.date, event.time) }))
      .filter(event => event.date > now);

    futureEvents.sort((a, b) => a.date - b.date);

    setNextEvent(futureEvents[0]);
  }, [view]);

  const handleViewChange = (newView) => {
    if (newView !== view) {
      setView(newView);
    }
  };

  const formatDate = (dateString, timeString) => {
    const date = createLocalDate(dateString, timeString);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return timeString ? date.toLocaleDateString(undefined, options) : date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <Container className="events-container">
      <h2>Programme du T.C. Neupré</h2>
      <div className="event-slider">
        <span 
          className={`slider-option ${view === 'upcoming' ? 'active' : ''}`} 
          onClick={() => handleViewChange('upcoming')}
        >
          À venir
        </span>
        <span 
          className={`slider-option ${view === 'past' ? 'active' : ''}`} 
          onClick={() => handleViewChange('past')}
        >
          Archives
        </span>
        <div className="slider-separator"></div>
      </div>
      <div className="events-table">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {view === 'upcoming' && upcomingEvents.map((event, index) => (
              <tr key={index} className={nextEvent && nextEvent.date.getTime() === createLocalDate(event.date, event.time).getTime() ? 'highlight' : ''}>
                <td>{formatDate(event.date, event.time)}</td>
                <td>{event.description}</td>
              </tr>
            ))}
            {view === 'past' && pastEvents.map((event, index) => (
              <tr key={index}>
                <td>{formatDate(event.date, event.time)}</td>
                <td>{event.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default Events;
