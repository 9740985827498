import React from 'react';
import './styles/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TopBar from "./Components/Homepage/TopBar.js";
import Header from "./Components/Homepage/Header.js";
import Footer from "./Components/Homepage/Footer.js";
import Carousel from "./Components/Homepage/Carousel.js";
import '@fortawesome/fontawesome-free/css/all.min.css';
import HeroAbout from "./Components/Homepage/HeroAbout.js";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cafetaria from "./Components/Cafeteria/Cafetaria.js";
import Banner from "./Components/Misc/Banner.js";
import banner1 from "./images/banner1.png";
import ScrollToTop from "./Components/Misc/ScrollToTop.js";
import PresentationPage from "./Components/About/PresentationPage.js";
import Stages from "./Components/Cours_stages/Stages.js";
import History from "./Components/About/History.js";
import Tarifs from "./Components/Abonnements/Tarifs.js";
import Accidents from "./Components/Misc/Accidents.js";
import PageReglementInterieur from './Components/Misc/PageReglementInterieur.js';
import Courts from './Components/Homepage/Courts.js';
import Events from './Components/Events/Events.js';
import CoursHiver from './Components/Cours_stages/CoursHiver.js';
import NosEcoles from './Components/Homepage/NosEcoles.js';
import PadelCourses from './Components/Cours_stages/PadelCourses.js';
import PadelStages from './Components/Cours_stages/PadelStages.js';
import NextEvent from './Components/Homepage/NextEvent.js';
import PartnersCarousel from './Components/Homepage/PartnersCarousel.js';
import bannerpadel from './images/imagebank/padel24.jpg';
import bannertennis from './images/imagebank/tennis9.jpg';
import Wip from './Components/Misc/wip.js'
import ContactForm from './Components/Contact/ContactForm.js';
import EquipePadel from './Components/Cours_stages/EquipePadel.js';
import ReservationPadel from './Components/Misc/ReservationPadel.js'

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/restauration" element={<CafeteriaPage />} />
        <Route path="/apropos" element={<Presentation />} />
        <Route path="/stagestennis" element={<StagesTennisPage />} />
        <Route path="/historique" element={<HistoryPage />} />
        <Route path="/tarifs" element={<TarifsPage />} />
        <Route path="/accidents" element={<AccidentsPage />} />
        <Route path="/roi" element={<RoiPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/tennishiver" element={<TennisHiverPage />} />
        <Route path="/infospadel" element={<InfosPadelPage />} />
        <Route path="/stagespadel" element={<StagesPadelPage />} />
        <Route path="/tennisprintemps" element={<TennisPrintempsPage />} />
        <Route path="/equipetennis" element={<EquipeTennisPage />} />
        <Route path="/equipepadel" element={<EquipePadelPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/reservationpadel" element={<ReservationPadelPage />} />
      </Routes>
      <ScrollToTop />
    </Router>
  );
}

const HomePage = () => (
  <div>
    <TopBar />
    <Header />
    <Carousel />
    <HeroAbout />
    <NosEcoles />
    <Courts />
    <NextEvent /> 
    <PartnersCarousel />
    <Footer />
  </div>
);

const CafeteriaPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={banner1} text="Cafetéria" />
    <Wip />
    <Footer />
  </div>
);

const Presentation = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={banner1} text="Le club" />
    <PresentationPage />
    <Footer />
  </div>
);

const StagesTennisPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={banner1} text="Stages" />
    <Stages />
    <Footer />
  </div>
);
const HistoryPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={banner1} text="Historique du club" />
    <History />
    <Footer />
  </div>
);
const TarifsPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={banner1} text="Tarifs" />
    <Tarifs />
    <Footer />
  </div>
);

const AccidentsPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={banner1} text="Accidents" />
    <Accidents />
    <Footer />
  </div>
);

const RoiPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={banner1} text="Règlement d'ordre intérieur" />
    <PageReglementInterieur />
    <Footer />
  </div>
);
const EventsPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={bannertennis} text="Évènements" />
    <Events />
    <Footer />
  </div>
);

const TennisHiverPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={bannertennis} text="Cours Hiver" />
    <CoursHiver />
    <Footer />
  </div>
);

const InfosPadelPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={bannerpadel} text="Sessions Padel" />
    <PadelCourses />
    <Footer />
  </div>
);

const StagesPadelPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={bannerpadel} text="Stages Padel" />
    <PadelStages />
    <Footer />
  </div>
);

const TennisPrintempsPage = () => (
  <div>
  <TopBar />
  <Header />
  <Banner image={bannertennis} text="Cours Printemps" />
  <Wip />
  <Footer />
</div>
)

const EquipeTennisPage = () => (
  <div>
  <TopBar />
  <Header />
  <Banner image={bannertennis} text="Notre équipe" />
  <Wip />
  <Footer />
</div>
)

const EquipePadelPage = () => (
  <div>
  <TopBar />
  <Header />
  <Banner image={bannerpadel} text="Notre équipe" />
  <EquipePadel />
  <Footer />
</div>
)

const ContactPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={banner1} text="Nous contacter" />
    <ContactForm />
    <Footer />
  </div>
);

const ReservationPadelPage = () => (
  <div>
    <TopBar />
    <Header />
    <Banner image={banner1} text="Réserver un terrain de padel" />
    <ReservationPadel />
    <Footer />
  </div>
);

export default App;
