import React, { useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import '../../styles/Cours_stages/CoursHiver.css';
import epsylon from '../../images/epsylon.png';
import inscriptionstennisHiver2024 from '../../inscriptionstennis_hiver2024.pdf';


const CoursHiver = () => {
  const [showCompetitive, setShowCompetitive] = useState(false);

  const handleShowClassics = () => setShowCompetitive(false);
  const handleShowCompetitive = () => setShowCompetitive(true);

  return (
    <Container className="cours-hiver-container">
      <div className="button-group">
        <Button 
          variant={showCompetitive ? "outline-primary" : "primary"} 
          onClick={handleShowClassics}
        >
          Cours classiques
        </Button>
        <Button 
          variant={showCompetitive ? "primary" : "outline-primary"} 
          onClick={handleShowCompetitive}
        >
          Cours compétitifs
        </Button>
      </div>

      {showCompetitive ? (
        <Card className="cours-content">
          <Card.Body>
            <h3>Team Compétition</h3>
            <p>Direction: Simon Arnold</p>
            <p>Programme sur demande à <a href="mailto:simonarnoldtennis@gmail.com">simonarnoldtennis@gmail.com</a></p>
          </Card.Body>
        </Card>
      ) : (
        <Card className="cours-content">
          <Card.Body>
            <Row>
              <Col md={6}>
                <img src={epsylon} alt="Winter Session" className="img-fluid" />
              </Col>
              <Col md={6}>
                <h3>Automne - Hiver 2024-2025</h3>
                <p>À partir du 30 septembre</p>
                <ul>
                  <li>20 semaines de cours</li>
                  <li>Vous conservez votre terrain habituel pour vous entrainer pendant les vacances scolaires</li>
                </ul>
                <div className="cours-details">
                  <p>Cours à 4 personnes - 1h/semaine p/p: <span className="prix">355 €</span></p>
                  <p>Cours à 3 personnes - 1h/semaine p/p: <span className="prix">435 €</span></p>
                  <p>Cours à 2 personnes - 1h/semaine p/p: <span className="prix">585 €</span></p>
                  <p>Cours particuliers 1h/semaine: <span className="prix">1.075 €</span></p>
                </div>
                <div className="alert alert-info mt-3">
                  <p>Effectuez votre paiement au club, en cash, lors de la journée d’inscription du samedi 31 août entre 10h et 13h et bénéficiez d’une réduction de 25€</p>
                  <p>Si vous ne pouvez pas vous libérer à cette occasion, envoyez dès à présent <a href={inscriptionstennisHiver2024} download="inscriptionstennis_hiver2024.pdf">votre fiche d’inscription</a> lisiblement complétée à <a href="mailto:neupretennischallenge@gmail.com">neupretennischallenge@gmail.com</a></p>
                </div>
              </Col>
            </Row>
            <div className="text-center mt-3">
              <p>Inscriptions & Infos: <a href="mailto:neupretennischallenge@gmail.com">neupretennischallenge@gmail.com</a></p>
              <p>ASBL Epsylon Neupré | BE51 0018 6884 7062</p>
            </div>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default CoursHiver;
