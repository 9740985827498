import React from 'react';
import { Container, Carousel, Table } from 'react-bootstrap';
import '../../styles/About/History.css';

import image1 from '../../images/imagebank/tennis5.jpg';
import image2 from '../../images/drone/tennisclub-13.jpg';
import image3 from '../../images/imagebank/tennis3.jpg';
import image4 from '../../images/imagebank/inaugurationpadel.png';
import image5 from '../../images/imagebank/DSC_2069_LuminarNeo-edit.jpg';
import image6 from '../../images/drone/tennisclub-7.jpg';


const History = () => {
  const milestones = [
    {
      title: 'Création du club',
      text: "L’ASBL Tennis Club Neupré a été créée en octobre 1976 par 2 neuvillois, Pierre et Marie-Jeanne Defrang.\n Marie-Jeanne est devenue Présidente Fondatrice, et Pierre son mari, le 1er secrétaire du club. Nous disposions de 2 terrains extérieurs en béton. Les premiers interclubs ont commencé un an plus tard. D’emblée, le club a connu un succès fulgurant. Les 2 terrains devenaient insuffisants pour les interclubs et en 1979, la commune de Neupré a cédé au club un terrain à Plainevaux avec un bail emphytéotique de 99 ans pour le franc symbolique. C’est le site actuel.",
      image: image1,
    },
    {
      title: 'De 2 à 7 terrains extérieurs',
      text: 'Dans un premier temps, construction du club-house (le chalet existe toujours), ainsi que 4 terrains. Nous avions pris l’option d’avoir aussi une saison d’hiver dès la première année du club à Plainevaux. C’est ainsi que l’on a créé une société sœur à l’ASBL, la Société Coopérative Tennis Neupré Hiver. Trois terrains supplémentaires ont été construits (pour être couverts en hiver), nous pouvions donc proposer aux membres 7 terrains extérieurs en été.\n\nUn match-exhibition inaugural a eu lieu en 1979. Il a opposé 2 joueurs du top 10 belge de l’époque, André Houssonlonge et ….Eric Marquet !\n\nDans les années 80, le club est devenu un des plus importants de la région avec un record de 624 membres. Nous disposions aussi d’une école de tennis dynamique.',
      image: image2,
    },
    {
      title: 'De la bulle à un hall',
      text: 'Après 8 ans de bons et loyaux services de la bulle, mais aussi après 2 sinistres suite à des tempêtes, le Conseil d’administration a décidé de construire un hall avec 5 nouveaux terrains en brique pilée, une cafétéria et des sanitaires. Deux terrains extérieurs ont été ajoutés derrière le hall. Celui-ci a été inauguré en octobre 1987. Le club voit alors sa structure presque définitive avec un total de 14 terrains. La société coopérative est dissoute.',
      image: image3,
    },
    {
      title: "L'arrivée des terrains de padel extérieurs",
      text: 'En avril 2021, nous avons inauguré 2 terrains de padel extérieurs. Ces installations modernes ont été un ajout précieux pour notre club, permettant à nos membres de découvrir et de pratiquer ce sport en pleine croissance.',
      image: image4,
    },
    {
      title: "Inauguration des terrains de padel intérieurs",
      text: 'En 2023, nous avons inauguré 2 terrains de padel intérieurs, offrant ainsi à nos membres la possibilité de jouer au padel toute l\'année, quel que soit le temps.',
      image: image5,
    },
    {
      title: "Aujourd’hui",
      text: 'Aujourd’hui, le hall a été agrandi à 6 terrains intérieurs en French court et deux terrains extérieurs ont été ajoutés. Le TC Neupré possède aujourd’hui 8 terrains extérieurs de tennis, dont 7 avec éclairage, et 6 terrains intérieurs de tennis avec des éclairages LED. En plus des terrains de tennis, le club dispose de 4 terrains de padel, dont 2 terrains intérieurs et 2 terrains extérieurs. Le club continue de moderniser ses installations pour offrir les meilleures conditions de jeu à ses membres, permettant la pratique du tennis et du padel tout au long de l\'année.',
      image: image6,
    },
  ];

  const presidents = [
    { name: 'Marie-Jeanne DEFRANG', years: '1976 – 1979' },
    { name: 'Philippe GUYOT', years: '1979 – 1981' },
    { name: 'Guy DUMOULIN', years: '1981 – 1983 et 1988 – 1989' },
    { name: 'Francis PEETERMANS', years: '1983 – 1988' },
    { name: 'Marcel ROUFFART', years: '1989 – 2005' },
    { name: 'Jean-Marc FORRO', years: '2005 – 2017' },
    { name: 'Anne-Catherine CROUFER', years: '2017 – 2019' },
    { name: 'Heike Davin et Lory Owieczka', years: '2019 – 2023' },
    { name: 'Heike Davin', years: '2023 – ...' }
  ];

  return (
    <Container className="history-page">
      <h1 className="text-center my-4">Historique du Club</h1>
      <Carousel controls={true} indicators={false} interval={null}>
        {milestones.map((milestone, index) => (
          <Carousel.Item key={index}>
            <h2 className="text-center my-4">{milestone.title}</h2>
            <img
              className="d-block w-100"
              src={milestone.image}
              alt={`Milestone ${index + 1}`}
            />
            <p className="text-center mt-4">{milestone.text}</p>
          </Carousel.Item>
        ))}
      </Carousel>
      <h2 className="my-4">Les présidents</h2>
      <Table responsive bordered hover className="presidents-table">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Année</th>
          </tr>
        </thead>
        <tbody>
          {presidents.map((president, index) => (
            <tr key={index}>
              <td>{president.name}</td>
              <td>{president.years}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default History;
