const equipePadelData = [
    {
      id: 1,
      name: 'Mathias Wibin',
      photo: require('../../images/equipePadel/mathias.jpg'),
      description: '',
    },
    {
      id: 2,
      name: 'Benoît Van Esch',
      photo: require('../../images/equipePadel/benoit.jpg'),
      description: '',
    },
    {
      id: 3,
      name: 'William Beauchamp',
      photo: require('../../images/equipePadel/william.jpg'),
      description: '',
    },
    {
      id: 4,
      name: 'Nicolas Van Zuylen',
      photo: require('../../images/equipePadel/nicolas.jpg'),
      description: '',
    },
    {
        id: 5,
        name: 'Raphaël Pace',
        photo: require('../../images/equipePadel/raphael.jpg'),
        description: '',
      },
      {
        id: 6,
        name: 'Marine Duhot',
        photo: require('../../images/equipePadel/marine.jpg'),
        description: '',
      },
      {
        id: 7,
        name: 'Olivier Scheyvaerts',
        photo: require('../../images/equipePadel/olivier.jpg'),
        description: '',
      },
      {
        id: 8,
        name: 'François Philippe Mathys',
        photo: require('../../images/equipePadel/francois.jpg'),
        description: '',
      },
  ];
  export default equipePadelData;
  