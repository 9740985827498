// src/eventsData.js

export const events = [
    { date: '2024-06-14', time: '', description: 'EURO Match d’ouverture Happy Hour' },
    { date: '2024-06-17', time: '18:00', description: 'EURO Belgique/Slovaquie' },
    { date: '2024-06-22', time: '21:00', description: 'EURO Belgique/Roumanie' },
    { date: '2024-06-26', time: '18:00', description: 'EURO Belgique/Ukraine' },
    { date: '2024-06-27', time: '', description: 'Tournoi Tennis aft + Messieurs I**' },
    { date: '2024-07-04', time: '', description: 'AFTER WORK avec DJ' },
    { date: '2024-07-06', time: '', description: 'Tournoi intime de Padel' },
    { date: '2024-07-26', time: '08:00', description: 'Tournoi afpadel' },
    { date: '2024-08-01', time: '', description: 'Tournoi Tennis aft' },
    { date: '2024-08-12', time: '', description: 'Tournoi afpadel' },
    { date: '2024-08-24', time: '', description: 'Tournoi intime Tennis / Padel / Pétanque' },
    { date: '2024-09-13', time: '', description: 'Coupe Phénix Tennis' },
    { date: '2024-09-19', time: '', description: 'Tournoi Tennis aft de Doubles' }
  ];
  