import React from 'react';
import { Table, Container, Alert } from 'react-bootstrap';
import '../../styles/Abonnements/AbonnementsEte.css';

const AbonnementsEte = () => {
  const isAvailable = false;

  return (
    <Container>
      <div className="availability-banner text-center my-4">
        {isAvailable ? (
          <Alert variant="success">Statut de la disponibilité des abonnements: Disponible</Alert>
        ) : (
          <Alert variant="danger">Statut de la disponibilité des abonnements: Plus d'abonnement disponible</Alert>
        )}
      </div>
      <h2 className="text-center my-4">Cotisations tennis - été 2024</h2>
      <Table bordered hover className="subscriptions-table">
        <thead>
          <tr>
            <th></th>
            <th>Habitants de Neupré</th>
            <th>Habitants hors Neupré</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Adultes</td>
            <td>185€</td>
            <td>190€</td>
          </tr>
          <tr>
            <td>Jeunes (nés entre 2007 & 2012)</td>
            <td>120€</td>
            <td>125€</td>
          </tr>
          <tr>
            <td>Enfants (nés entre 2013 & 2019)</td>
            <td>55€</td>
            <td>60€</td>
          </tr>
          <tr>
            <td>Sympathisant.e non joueur.euse</td>
            <td>40€</td>
            <td>40€</td>
          </tr>
        </tbody>
      </Table>
      <div className="inscription-details my-4">
        <h4>Comment vous inscrire ?</h4>
        <ul>
          <li>
            Renvoyer la fiche formulaire d’inscription saison 2024 complétée par mail à l’adresse suivante :
            <a href="mailto:info@neupretennispadel.be"> info@neupretennispadel.be</a>
          </li>
          <li>
            Régler le montant de la cotisation par virement bancaire
            <ul>
              <li>Numéro de compte : BE39 0011 3464 0019</li>
              <li>Destinataire : TC NEUPRE</li>
              <li>Communication libre : NOM + PRENOM (si plusieurs personnes, indiquez tous les membres qui en bénéficient)</li>
            </ul>
          </li>
          <li>
            Attention, TOUS les joueurs et joueuses doivent compléter le formulaire d’inscription ci-dessous,
            en ce compris ceux qui ont déjà payé leur(s) cotisation(s), ce document est nécessaire pour vous activer dans Webclub
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default AbonnementsEte;
