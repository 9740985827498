import React from 'react';
import { Container, Button } from 'react-bootstrap';
import '../../styles/Misc/ReglementInterieur.css';

const PageReglementInterieur = () => {
  return (
    <Container className="reglement-interieur-page">
      <h1 className="text-center my-4">Règlement d'ordre intérieur</h1>
      <p className="introduction-text">
        Bienvenue sur la page dédiée au règlement d'ordre intérieur de notre club. Ce document est essentiel pour garantir 
        le bon fonctionnement et la convivialité au sein de notre communauté. Il est important que chaque membre prenne le 
        temps de lire et de comprendre ces règles afin de contribuer à maintenir une atmosphère agréable et respectueuse pour 
        tous. Vous trouverez dans ce règlement toutes les informations nécessaires concernant les comportements attendus, les 
        sanctions en cas de non-respect des règles, ainsi que les modalités d'utilisation des installations du club.
      </p>
      <div className="text-center my-4">
        <Button variant="primary" href="https://downloads.bjstatic.com/2a979bb5833bad9ee3fd9d2dd0ce6dff/ROI_2023.pdf" target="_blank">
          Accéder au règlement d'ordre intérieur
        </Button>
      </div>
    </Container>
  );
};

export default PageReglementInterieur;
