import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import EquipePadelMember from './equipePadelMember';
import equipePadelData from './equipePadelData';
import '../../styles/About/PresentationPage.css'; 

const EquipePadelPage = () => {
  return (
    <Container className="presentation-page">
      {/* Section Texte */}
      <section className="highlight-section my-4">
        <h2 className ="text-center">Nos entraineurs de Padel</h2>
        <p>
          Notre équipe d'entraîneurs padel est composée de professionnels passionnés et expérimentés, prêts à vous aider à améliorer votre jeu et à atteindre vos objectifs.
        </p>
      </section>

      {/* Section Équipe Padel */}
      <section className="equipe-padel-section my-4">
        <h3 className ="text-center">Les membres de l'équipe</h3>
        <Row>
          {equipePadelData.map(member => (
            <Col md={3} key={member.id}>
              <EquipePadelMember member={member} />
            </Col>
          ))}
        </Row>
      </section>
    </Container>
  );
};

export default EquipePadelPage;
