import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaFilePdf, FaEnvelope } from 'react-icons/fa';
import '../../styles/Misc/Accidents.css';
const Accidents = () => {
  return (
    <Container className="accidents-page">
      <Row className="my-5">
        <Col md={12}>
          <h2>Procédure en cas d'accident</h2>
          <ol className="procedure-list">
            <li>
              <FaFilePdf className="icon" />
              <span>
                Télécharger et imprimer le formulaire de déclaration d'accident de l'Association Francophone de Tennis en cliquant sur le lien suivant : 
                <a href="http://www.aftnet.be/Aftnet/media/Media/Documents/Formulaire-declaration-d-accident.pdf" target="_blank" rel="noopener noreferrer">
                  Formulaire de déclaration d'accident
                </a>.
              </span>
            </li>
            <li>
              <FaFilePdf className="icon" />
              <span>
                Remplir le formulaire en veillant à compléter toutes les rubriques.
              </span>
            </li>
            <li>
              <FaEnvelope className="icon" />
              <span>
                Envoyer le formulaire rempli et le certificat médical (s'il existe) par e-mail à :
                <a href="mailto:manonrigel@gmail.com">manonrigel@gmail.com</a>
              </span>
            </li>
          </ol>
          <p className="important-note">
            Votre déclaration d'accident sera envoyée à ETHIAS via leur système informatique EXTRANET.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Accidents;
