import React from 'react';
import { Container, Button } from 'react-bootstrap';
import '../../styles/Misc/ReservationPadel.css';

const ReservationPadel = () => {
  return (
    <div className="reservation-container">
      <Container>
        <div className="reservation-content">
          <h1>Réservation de terrain de Padel</h1>
          <p>
            Pour réserver un terrain de padel, deux plateformes différentes sont utilisées :
          </p>
          <ul>
            <li>
              <strong>Balle Jaune</strong> : Pour réserver les terrains extérieurs, disponibles pour nos membres ou pour des personnes extérieures avec un pass invité.
            </li>
            <li>
              <strong>SportFinder</strong> : Pour réserver les terrains intérieurs.
            </li>
          </ul>
          <div className="reservation-buttons">
            <Button 
              variant="primary" 
              href="https://ballejaune.com/club/Tc-Neupre" 
              className="reservation-button">
              Réserver un terrain extérieur
            </Button>
            <Button 
              variant="primary" 
              href="https://www.sport-finder.com/fr/center/tc-neupre-padel-club" 
              className="reservation-button">
              Réserver un terrain intérieur
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ReservationPadel;
