import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import tennisHiverImage from '../../images/interieur/tennisclub-31.jpg';
import tennisPrintempsImage from '../../images/tennis.png';
import equipeImage from '../../images/equipe.jpg';
import resultatsImage from '../../images/resultats.jpg';
import padelSessionsImage from '../../images/interieur/tennisclub-51.jpg';

import '../../styles/Homepage/NosEcoles.css';

const NosEcoles = () => {
  const [activeSchool, setActiveSchool] = useState('tennis');

  const tennisContent = (
    <Row>
      <Col md={3} sm={6} xs={12} className="mb-4">
        <Card as={Link} to="/tennishiver" className="text-center">
          <div className="image-container">
            <Card.Img variant="top" src={tennisHiverImage} />
          </div>
          <Card.Body>
            <Card.Title>Cycle Hiver</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3} sm={6} xs={12} className="mb-4">
        <Card as={Link} to="/tennisprintemps" className="text-center">
          <div className="image-container">
            <Card.Img variant="top" src={tennisPrintempsImage} />
          </div>
          <Card.Body>
            <Card.Title>Cycle Printemps</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3} sm={6} xs={12} className="mb-4">
        <Card as={Link} to="/equipetennis" className="text-center">
          <div className="image-container">
            <Card.Img variant="top" src={equipeImage} />
          </div>
          <Card.Body>
            <Card.Title>L'équipe</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3} sm={6} xs={12} className="mb-4">
        <Card as="a" href="https://tennis.tennispadelwalloniebruxelles.be/MyAFT/Competitions/Index" target="_blank" className="text-center">
          <div className="image-container">
            <Card.Img variant="top" src={resultatsImage} />
          </div>
          <Card.Body>
            <Card.Title>Résultats des joueurs du TCN</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  const padelContent = (
    <Row className="justify-content-center">
      <Col md={3} sm={6} xs={12} className="mb-4">
        <Card as={Link} to="/infospadel" className="text-center">
          <div className="image-container">
            <Card.Img variant="top" src={tennisHiverImage} />
          </div>
          <Card.Body>
            <Card.Title>Sessions</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3} sm={6} xs={12} className="mb-4">
        <Card as={Link} to="/stagespadel" className="text-center">
          <div className="image-container">
            <Card.Img variant="top" src={tennisPrintempsImage} />
          </div>
          <Card.Body>
            <Card.Title>Stages</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col md={3} sm={6} xs={12} className="mb-4">
        <Card as={Link} to="/equipepadel" className="text-center">
          <div className="image-container">
            <Card.Img variant="top" src={equipeImage} />
          </div>
          <Card.Body>
            <Card.Title>L'équipe</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  return (
    <Container className="nos-ecoles">
      <h2 className="text-center my-4">Nos écoles</h2>
      <div className="text-center mb-4">
        <Button
          variant={activeSchool === 'tennis' ? 'primary' : 'secondary'}
          onClick={() => setActiveSchool('tennis')}
        >
          Tennis
        </Button>
        <Button
          variant={activeSchool === 'padel' ? 'primary' : 'secondary'}
          onClick={() => setActiveSchool('padel')}
        >
          Padel
        </Button>
      </div>
      {activeSchool === 'tennis' ? tennisContent : padelContent}
    </Container>
  );
};

export default NosEcoles;
