import React, { useState } from 'react';
import { Navbar, Nav, Container, NavDropdown, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaHome, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import logo from "../../images/logo.png";
import '../../styles/Homepage/Header.css';
import Signup from '../AccountManagement/Signup';
import Login from '../AccountManagement/Login';

const Header = () => {
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const handleShowSignup = () => setShowSignup(true);
  const handleCloseSignup = () => setShowSignup(false);
  const handleShowLogin = () => setShowLogin(true);
  const handleCloseLogin = () => setShowLogin(false);

  return (
    <Navbar bg="light" expand="lg" className="navbar-custom">
      <Container>
        <Navbar.Brand as={Link} to="/" className="navbar-brand-custom">
          <img
            src={logo}
            height="60"
            className="d-inline-block align-top"
            alt="Tennis Club Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className="nav-item"><FaHome className="nav-icon" /> ACCUEIL</Nav.Link>
            <NavDropdown title={<span>CLUB <FaChevronDown className="main-menu-arrow" /></span>} id="club-dropdown" className="nav-item">
              <NavDropdown.Item as={Link} to="/apropos">À Propos</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/historique">Historique</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={<span>INFOS <FaChevronDown className="main-menu-arrow" /></span>} id="infos-dropdown" className="nav-item">
              <NavDropdown.Item as={Link} to="/tarifs">Tarifs</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/accidents">Accidents</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/roi">Règlement d'ordre intérieur</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={<span>ÉCOLE DE TENNIS <FaChevronDown className="main-menu-arrow" /></span>}
              id="tennis-school-dropdown"
              className="dropdown-submenu nav-item"
            >
              <NavDropdown title={<span>Sessions <FaChevronRight className="submenu-arrow" /></span>} id="sessions-dropdown" className="dropdown-submenu">
                <NavDropdown.Item as={Link} to="/tennishiver">Hiver</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/tennisprintemps">Printemps</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown.Item as={Link} to="/stagestennis">Stages</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/equipetennis">Equipe</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={<span>ÉCOLE DE PADEL <FaChevronDown className="main-menu-arrow" /></span>} id="padel-school-dropdown" className="nav-item">
              <NavDropdown.Item as={Link} to="/infospadel">Sessions</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/stagespadel">Stages</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/equipepadel">Equipe</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/restauration" className="nav-item">RESTAURATION</Nav.Link>
            <Nav.Link as={Link} to="/events" className="nav-item">ÉVÉNEMENTS</Nav.Link>
            <Nav.Link as={Link} to="/contact" className="nav-item">CONTACT</Nav.Link>
            {/* <Nav.Link className="nav-item" onClick={handleShowLogin}>Se connecter</Nav.Link>
            <Nav.Link className="nav-item" onClick={handleShowSignup}>S'inscrire</Nav.Link> */} 
            {/* à réactiver une fois que les comptes seront fonctionnels */}
          </Nav>
        </Navbar.Collapse>
      </Container>

      <Modal show={showSignup} onHide={handleCloseSignup}>
        <Modal.Header closeButton>
          <Modal.Title>S'inscrire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Signup />
        </Modal.Body>
      </Modal>

      <Modal show={showLogin} onHide={handleCloseLogin}>
        <Modal.Header closeButton>
          <Modal.Title>Se connecter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Login />
        </Modal.Body>
      </Modal>
    </Navbar>
  );
};

export default Header;
