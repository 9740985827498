import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import tennisImage from '../../images/imagebank/DSC_2069_LuminarNeo-edit.jpg'; 
import '../../styles/Homepage/HeroAbout.css';

const HeroAbout = () => {
  return (
    <div className="hero-about">
      <Container>
        <Row className="align-items-center">
          <Col md={5}>
            <div className="image-container">
              <img src={tennisImage} alt="Tennis" className="img-fluid rounded shadow" />
            </div>
          </Col>
          <Col md={7}>
            <h2 className="hero-title">À propos du club</h2>
            <p>
              Le Tennis Padel Club Neupré est situé dans un cadre verdoyant et champêtre, à seulement 20 minutes de Liège et 10 minutes de Nandrin. Le club dispose de deux grands parkings de part et d'autre des terrains et d'une infrastructure sécurisée pour les enfants.
            </p>
            <Button href="/apropos" variant="primary">EN SAVOIR +</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroAbout;
