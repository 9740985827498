import React from 'react';
import { Card } from 'react-bootstrap';
import '../../styles/Cours_stages/EquipePadelMember.css'; 

const EquipePadelMember = ({ member }) => {
  return (
    <Card className="equipe-padel-member">
      <div className="image-container">
        <Card.Img variant="top" src={member.photo} alt={member.name} className="fixed-dimensions"/>
      </div>
      <Card.Body>
        <Card.Title>{member.name}</Card.Title>
        <Card.Text>{member.description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default EquipePadelMember;
