import React from 'react';
import '../../styles/Misc/Banner.css';

const Banner = ({ image, text }) => {
  return (
    <div className="banner" style={{ backgroundImage: `url(${image})` }}>
      <div className="banner-text">
        <h1>{text}</h1>
      </div>
    </div>
  );
};

export default Banner;
