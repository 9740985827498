import React from 'react';
import { Table, Container, Button, Alert } from 'react-bootstrap';
import '../../styles/Abonnements/ReservationsTennis.css';

const ReservationsTennis = () => {
  return (
    <Container>
      <h1 className="text-center my-4">Réservations – tarif horaire</h1>
      <Table bordered hover className="reservations-table">
        <thead>
          <tr>
            <th></th>
            <th>9h-17h</th>
            <th>17h-22h</th>
            <th>Après 22h</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Du lundi au vendredi</td>
            <td>15€</td>
            <td>20€</td>
            <td>15€</td>
          </tr>
          <tr>
            <td>Week-end</td>
            <td>20€</td>
            <td>20€</td>
            <td>15€</td>
          </tr>
        </tbody>
      </Table>

      <div className="reservation-options text-center my-4">
        <Button variant="primary" href="https://ballejaune.com/club/Tc-Neupre" target="_blank" className="mx-2">
          Réservation via Balle Jaune
        </Button>
        <div className="phone-reservation my-2">
          ou par téléphone : <a href="tel:+3243713570">04 371 35 70</a>
        </div>
      </div>

      <Alert variant="danger" className="text-center mt-4">
        <strong>Important:</strong> Si vous réservez par téléphone, il est <strong>OBLIGATOIRE</strong> de payer votre réservation avant d'utiliser le terrain.
      </Alert>
    </Container>
  );
};

export default ReservationsTennis;
