const committeeData = [
  {
    id: 1,
    name: 'Heike Davin',
    photo: require('../../images/comite/heike.png'),
    description: 'heike.davin@neupretennispadel.be',
  },
  {
    id: 2,
    name: 'Bernard Sanz',
    photo: require('../../images/comite/bernard.png'),
    description: 'bernard.sanz@neupretennispadel.be',
  },
  {
    id: 3,
    name: 'Martine Frère',
    photo: require('../../images/comite/martine.png'),
    description: 'martine.frere@neupretennispadel.be',
  },
  {
    id: 4,
    name: 'Olivier Scheyvaerts',
    photo: require('../../images/comite/olivier.png'),
    description: 'olivier.scheyvaerts@neupretennispadel.be',
  },
  {
    id: 5,
    name: 'Jason Servais',
    photo: require('../../images/comite/jason.png'),
    description: 'jason.servais@neupretennispadel.be',
  },
  {
    id: 6,
    name: 'Philippe Bouchy',
    photo: require('../../images/comite/philippe.png'),
    description: 'philippe.bouchy@neupretennispadel.be',
  },
  {
    id: 7,
    name: 'Marine Duhot',
    photo: require('../../images/comite/marine.png'),
    description: 'marine.duhot@neupretennispadel.be',
  },
  {
    id: 7,
    name: 'Maxime Vandenberghe',
    photo: require('../../images/comite/placeholder.png'),
    description: 'maxime.vandenberghe@neupretennispadel.be',
  },
  {
    id: 8,
    name: 'Philippe Kaesmacher',
    photo: require('../../images/comite/placeholder.png'),
    description: 'philippe.kaesmacher@neupretennispadel.be',
  },
];

export default committeeData;
