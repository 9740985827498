import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import CommitteeMember from './CommitteeMember';
import committeeData from './committeeData';
import '../../styles/About/PresentationPage.css'; 
import ImageGalleryTerrains from "./ImageGalleryTerrains"

const PresentationPage = () => {
  return (
    <Container className="presentation-page">
      {/* Section Texte */}
      <section className="highlight-section my-4">
        <h2 className ="text-center">Le Tennis Padel Club de Neupré</h2>
        <p>
          Le club compte 17 terrains. À l’extérieur, lorsque la saison d’été commence et jusqu’en septembre, les joueurs ont accès à 7 terrains en brique pilée. 6 d’entre eux sont éclairés et sont ainsi accessibles jusque 23h. Le club est entièrement sécurisé pour les enfants avec un espace de jeux : mur pour s’entraîner au tennis et module.
        </p>
        <p>
          En hiver mais également lorsqu’il pleut en été, le club dispose d’un hall éclairé et chauffé où les joueurs se rencontrent sur 6 terrains.
        </p>
        <p>
          Le club compte également une salle de sport et des vestiaires et douches.
        </p>
        <p>
          Une cafétéria, accessible 7 jours sur 7, accueille, dans une ambiance conviviale, toutes les personnes, joueurs de tennis ou non, qui désirent boire un verre ou manger. La cafétéria, vitrée, permet une grande visibilité sur les terrains intérieurs.
        </p>
        <p>
          Enfin, 2 grands parkings jouxtent le club de part et d’autre.
        </p>
      </section>

      {/* Galerie d'Images */}
      <section className="gallery-section my-4">
        <ImageGalleryTerrains />
      </section>

      {/* Section Comité */}
      <section className="committee-section my-4">
        <h3 className ="text-center">Notre Comité</h3>
        <Row>
          {committeeData.map(member => (
            <Col md={3} key={member.id}>
              <CommitteeMember member={member} />
            </Col>
          ))}
        </Row>
      </section>
    </Container>
  );
};

export default PresentationPage;
