import React from 'react';
import { Table, Row, Col, Card, Container, Button, Image } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';
import '../../styles/Cours_stages/PadelCourses.css';
import tenispadel from '../../images/tenispadel.png';

const PadelCourses = () => {
  const indoorCourses = [
    { type: 'Groupe de 4', price: '165€' },
    { type: 'Groupe de 3', price: '215€' },
    { type: 'Groupe de 2', price: '310€' },
    { type: 'Cours particulier', price: '600€' },
  ];

  const winterCourses = [
    { type: 'Groupe de 4', price: '120€' },
    { type: 'Groupe de 3', price: '150€' },
    { type: 'Groupe de 2', price: '220€' },
    { type: 'Cours particulier', price: '420€' },
  ];

  const schedule = [
    { day: 'Lundi', times: ['16H00', '17H00', '18H00', '19H00', '20H00', '21H00'] },
    { day: 'Mardi', times: ['16H00', '17H00', '18H00', '19H00', '20H00', '21H00'] },
    { day: 'Mercredi', times: ['13H00', '14H00', '15H00', '16H00'] },
    { day: 'Jeudi', times: ['En journée uniquement'] },
    { day: 'Vendredi', times: ['16H00', '17H00'] },
    { day: 'Samedi', times: ['9H00', '10H00', '11H00'] },
    { day: 'Dimanche', times: ['9H00', '10H00', '11H00', '12H00', '13H00'] },
  ];

  return (
    <Container className="padel-courses-container">
      <Image src={tenispadel} alt="Banner" className="img-fluid banner-image" />
      <Row className="justify-content-center mb-4">
        <Col md={8}>
          <Card className="info-card text-center">
            <Card.Body>
              <Card.Title>Responsable: Olivier Scheyvaerts</Card.Title>
              <Card.Text>
                Contact: <FaEnvelope /> neupretennischallenge@gmail.com
              </Card.Text>
              <Card.Text>
                Toutes les réservations de cours passent par le contact mail ou 0478/31.42.87 (Olivier Scheyvaerts)
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h2 className="text-center my-4">Cycle de cours indoor (10 semaines) - Octobre à décembre</h2>
      <Row className="justify-content-center mb-4">
        <Col md={8}>
          <Table responsive bordered hover className="courses-table">
            <thead>
              <tr>
                <th>Type de cours</th>
                <th>Prix (par personne)</th>
              </tr>
            </thead>
            <tbody>
              {indoorCourses.map((course, index) => (
                <tr key={index}>
                  <td>{course.type}</td>
                  <td><Button variant="danger" className="price-button">{course.price}</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <h2 className="text-center my-4">Cycle de cours indoor (7 semaines) - Janvier à mars</h2>
      <Row className="justify-content-center mb-4">
        <Col md={8}>
          <Table responsive bordered hover className="courses-table">
            <thead>
              <tr>
                <th>Type de cours</th>
                <th>Prix (par personne)</th>
              </tr>
            </thead>
            <tbody>
              {winterCourses.map((course, index) => (
                <tr key={index}>
                  <td>{course.type}</td>
                  <td><Button variant="danger" className="price-button">{course.price}</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <h2 className="text-center my-4">Horaires disponibles</h2>
      <Row className="justify-content-center mb-4">
        <Col md={10}>
          <Table responsive bordered hover className="schedule-table">
            <thead>
              <tr>
                <th>Jour</th>
                <th>Horaires</th>
              </tr>
            </thead>
            <tbody>
              {schedule.map((day, index) => (
                <tr key={index}>
                  <td>{day.day}</td>
                  <td>{day.times.join(', ')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <div className="alert alert-info text-center mt-4">
        Veuillez indiquer vos disponibilités et préférences en entourant les heures choisies et nous les renvoyer par mail de contact, merci. Priorité aux groupes constitués !
      </div>
      <div className="alert alert-danger text-center mt-4">
        Aucun cours ne sera reporté ou remboursé. Vous devez être en règle de paiement une semaine avant le commencement du cycle.
      </div>
    </Container>
  );
};

export default PadelCourses;
