import React from 'react';
import { Carousel } from 'react-bootstrap';
import terrain1 from '../../images/drone/tennisclub-11.jpg';
import terrain2 from '../../images/drone/tennisclub-21.jpg';
import terrain3 from '../../images/imagebank/tennis1.jpg';
import terrain4 from '../../images/interieur/tennisclub-50.jpg';
import terrain5 from '../../images/interieur/tennisclub-51.jpg';
import '../../styles/Misc/ImageGallery.css';

const ImageGallery = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src={terrain1} alt="Terrain 1" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={terrain2} alt="Terrain 2" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={terrain3} alt="Terrain 3" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={terrain4} alt="Terrain 4" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={terrain5} alt="Terrain 4" />
      </Carousel.Item>
    </Carousel>
  );
};

export default ImageGallery;
