import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBKoxPRrOJMoyldHjtp3SjVqoHLkOtRzyQ",
  authDomain: "YOUR_AUTH_DOMAIN",
  projectId: "tennis-neupre.firebaseapp.com",
  storageBucket: "tennis-neupre.appspot.com",
  messagingSenderId: "462042067028",
  appId: "1:462042067028:web:9ad154fb735c5f87a3fdc1"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


